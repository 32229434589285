import { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Checkbox,
  Tooltip,
  Tabs,
  notification,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
const TaskIssues = ({
  taskId,
  issues,
  verdicts,
  onIssueOverride,
  setIsSubmitDisabled,
  setRelevantDetails,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentIssues, setCurrentIssues] = useState([]);
  const [inputText, setInputText] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkboxToggled, setCheckboxToggled] = useState(false);
  const [detailsByIssue, setDetailsByIssue] = useState({});

  const userFriendlyGroupNames = {
    // Group names that deserve special attention that is not
    // just capitalization of the first letter (derived from our
    // page classifications).
    "cpap order": "CPAP Order",
    "bipap order": "BIPAP Order",
    "oat order": "OAT Order",
    "pap resupply": "PAP Resupply",
    "pap update": "PAP Update",
    "mwc order": "MWC Order",
    "pwc order": "PWC Order",
    "cgm order": "CGM Order",
    "standard_written_order.pcd": "PCD Order",
  };

  const groupFromKey = (key) => {
    // Given an issue key, compute a key for a group under which the issue
    // should be listed, and an order. Groups with lower order are rendered first.
    var components = key.split(".");
    //console.log("COMPONENTS: ", components);
    if (["dme_referral_issues", "packet"].includes(components[0])) {
      // There is your group key!
      return [components[0], 1];
    } else if (components[0] === "document") {
      // Document type is second key component.
      if (
        ["standard_written_order", "detailed_written_order"].includes(
          components[0]
        )
      ) {
        return [components.slice(1, 2).join("."), 1];
      }
      return [components[1], 1];
    } else if (components[0] == "tasks" && components[1] == "missing_doc") {
      return ["missing documents", 0];
    } else {
      return ["other", 2];
    }
  };

  const groupKeyCmp = (a, b) => {
    // Sort group keys, with primary being order, secondary key.
    if (a.order !== b.order) {
      return a.order - b.order;
    } else {
      return a.key.localeCompare(b.key);
    }
  };

  const groupIssues = (issues) => {
    // compute a list of issue groups, with each grop having order, key and
    // a list of issues under it.
    const issueDict = issues.reduce((acc, issue) => {
      const [groupKey, order] = groupFromKey(issue.key);
      acc[groupKey] = acc[groupKey] || {
        key: groupKey,
        order: order,
        issues: [],
      };
      acc[groupKey].issues.push(issue);
      return acc;
    }, {});

    const result = Object.values(issueDict).sort(groupKeyCmp);
    return result;
  };

  const headerFromGroupKey = (key) => {
    // Make a nice group header by capitalizing the first word of a key.
    if (key in userFriendlyGroupNames) {
      return userFriendlyGroupNames[key];
    }
    return key.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const showModal = (issueGroup) => {
    setCurrentIssues(issueGroup);
    setVisible(true);
    setInputText(issueGroup[0]?.comment || "");
    setChecked(issueGroup.every((issue) => issue.override));
    setCheckboxToggled(false);
  };

  const handleOk = async (action) => {
    let overrideReason = "";
    if (action === "override" && checkboxToggled && checked) {
      overrideReason = inputText;
    } else if (action === "details") {
      overrideReason = "Relevant Details";
      aggregateAndSetDetails(currentIssues[0]?.key, inputText); // Call to the aggregation function
    }
    try {
      currentIssues.forEach((issue) => {
        issue.override = checked;
        issue.comment = overrideReason;
        onIssueOverride({
          key: issue.key,
          override: checked,
          comment: overrideReason,
        });
      });
      setIsSubmitDisabled(false);
    } catch (error) {
      notification.error({
        message: "Failed to update issue override.",
      });
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const aggregateAndSetDetails = (issueKey, value) => {
    const updatedDetails = {
      ...detailsByIssue,
      [issueKey]: (detailsByIssue[issueKey] || "") + "\n" + value.trim(), // Append new details to existing
    };
    setDetailsByIssue(updatedDetails);
    setRelevantDetails(Object.values(updatedDetails).join("\n")); // Convert all details into a single string for external use
  };

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
    setCheckboxToggled(true);
  };

  const render_issues = (issues) => {
    const grouped_issues = groupIssues(issues);

    if (issues.length == 0) {
      return <div></div>;
    }

    return (
      <div>
        <h3 style={{ marginTop: "0px" }}>
          Override any of the following requirements by clicking the{" "}
          <WarningOutlined style={{ fontSize: "18px" }} /> next to it and
          providing a reason
        </h3>
        {grouped_issues.map((issueGroup, index) => (
          <div key={index}>
            <div
              style={{
                marginBottom: "-10px",
              }}
            >
              <b>{headerFromGroupKey(issueGroup.key)}</b>
            </div>

            <ul
              style={{
                listStyleType: "none",
              }}
            >
              {issueGroup.issues.map((issue) => (
                <li
                  key={`${taskId}:${issue.key}`}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "24px 1fr", // Icon column and text column
                    columnGap: "10px",
                    marginBottom: "12px",
                  }}
                >
                  <div style={{ alignSelf: "start" }}>
                    {issue.override ? (
                      <Tooltip title={issue.comment}>
                        <CheckCircleOutlined
                          style={{
                            color: "green",
                            fontSize: "18px",
                            marginRight: "10px",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Click to override">
                        <WarningOutlined
                          onClick={() => showModal([issue])}
                          style={{
                            color: "red",
                            fontSize: "18px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <span>{issue.message}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const render_verdict_issues = (verdict) => {
    const verdict_issues = verdict.issues.map((idx) => issues[idx]);
    if (verdict.issues.length == 0) {
      return <h2>No issues found</h2>;
    }

    return render_issues(verdict_issues);
  };

  return (
    <>
      {verdicts.length > 1 ? (
        <Tabs
          defaultActiveKey={0}
          items={verdicts.map((verdict, i) => {
            const issues = render_verdict_issues(verdict);

            // Prepend checkmark or X to indicate approval state.
            const label = (
              <span>
                {verdict.approved ? (
                  <CheckOutlined
                    style={{ color: "green", marginRight: "5px" }}
                  />
                ) : (
                  <CloseOutlined style={{ color: "red", marginRight: "5px" }} />
                )}
                {verdict.code}
              </span>
            );

            return {
              label,
              key: i,
              children: issues,
            };
          })}
        />
      ) : (
        <>{render_issues(issues)}</>
      )}

      <Modal
        title="Manage Issue"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submitOverride"
            type="primary"
            onClick={() => handleOk("override")}
            disabled={!checkboxToggled || !checked || !inputText}
          >
            Override Issue
          </Button>,
          <Button
            key="submitDetails"
            type="primary"
            onClick={() => handleOk("details")}
            disabled={!checkboxToggled || !checked || !inputText}
          >
            Add Relevant Details
          </Button>,
        ]}
      >
        <p>
          Please enter the reason for overriding this issue or provide relevant
          details:
        </p>
        <Input.TextArea value={inputText} onChange={handleInputChange} />
        <Checkbox checked={checked} onChange={handleCheckboxChange}>
          Confirm override
        </Checkbox>
      </Modal>
    </>
  );
};

export default TaskIssues;
