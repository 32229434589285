export const handleError = (error, defaultMessage = "An error occurred") => {
  if (error && typeof error === "object") {
    if (error.response && error.response.data && error.response.data.detail) {
      return error.response.data.detail;
    } else if (error.message) {
      return error.message;
    }
  } else if (typeof error === "string") {
    return error;
  }
  return defaultMessage;
};

const fetchDocumentCategory = (tDetails) => {
  const structuredData = tDetails.interceptions.structured_data || [];
  console.log(structuredData);
  return structuredData.find((item) => {
    return item.tag === "document-category";
  });
};

const fetchPageResults = (tDetails) => {
  const structuredData = tDetails.interceptions.structured_data || [];
  return (
    structuredData.find((item) => {
      return item.tag === "pages-details";
    })?.data || {}
  );
};

const fetchDocumentAnalysisPrompt = (tDetails) => {
  const llm_logs = tDetails.llm_logs || [];
  const docAnalysisPrompts = llm_logs.filter((item) => {
    return (
      item.prompt_short_name === "dme_referral_prompt" &&
      item.raw_prompt !== null
    );
  });
  if (docAnalysisPrompts.length > 0) {
    return docAnalysisPrompts;
  }
  return null;
};

export const ingestTaskDetails = (record, tDetails) => {
  return {
    selectedRow: record,
    uuid: tDetails.uuid,
    currentPhase: tDetails.current_phase + 1,
    additionalData: tDetails.interceptions.outputs || [],
    additionalDataInput: tDetails.interceptions.inputs || [],
    metadata: tDetails.metadata,
    structuredData: tDetails.interceptions.structured_data || [],
    uploadedAt: tDetails.uploaded_at,
    percentage_completed: tDetails.percentage_completed,
    event_logs: tDetails.event_logs,
    llm_logs: tDetails.llm_logs,
    documentCategory: fetchDocumentCategory(tDetails),
    pagesResults: fetchPageResults(tDetails),
    documentAnalysisPrompts: fetchDocumentAnalysisPrompt(tDetails),
    verdicts: tDetails.verdicts,
  };
};

export const validateMedicareNumber = (mbi) => {
  const nonzero = "[1-9]";
  const letter = "[AC-HJKMNP-RT-Yac-hjkmnp-rt-y]"; // Letters excluding S, L, O, I, B, Z
  const digit = "[0-9]";
  // eslint-disable-next-line
  const partial =
    "(" + letter + "(" + letter + "|" + digit + ")" + digit + "-?" + ")";
  const pattern = nonzero + partial + "{2}" + letter + "{2}" + digit + "{2}";

  return !!mbi.match(pattern);
};

export const formatMessage = (message, params = {}) => {
  return message.replace(/\{(\w+)\}/g, (match, key) => {
    return params[key] !== undefined ? params[key] : match;
  });
};
