// components/AddESignatureModal.js
import { Modal, Form, Input, DatePicker, Select } from "antd";
import { useState } from "react";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";

const { TextArea } = Input;
const { Option } = Select;
const AddESignatureModal = ({
  visible,
  taskId,
  fileData,
  pagesResults,
  onOk,
  onCancel,
  appendESignature,
}) => {
  const [form] = Form.useForm();
  const [pageOptions, setPageOptions] = useState([]);
  const [localPagesResults, setLocalPagesResults] = useState(pagesResults);

  console.log("fileData", fileData);
  console.log("pagesResults", pagesResults);

  // Handle file change
  const handleFileChange = (value) => {
    if (value) {
      console.log("Selected file ID:", value);
      // Update page options based on the selected file
      const selectedPages = pagesResults[value] || [];
      setPageOptions(selectedPages.map((page) => page.page_num));
    } else {
      setPageOptions([]); // Clear page options when no file is selected
    }
    form.setFieldsValue({ page: undefined }); // Reset the page select field
  };

  const handleUpdatePageData = async (
    values,
    pagesResults,
    setLocalPagesResults
  ) => {
    const { file, page, ...esignatureData } = values;
    try {
      // Update the local state for pagesResults
      const updatedPagesResults = { ...pagesResults };
      const currentPageIndex = updatedPagesResults[file].findIndex(
        (pageData) => pageData.page_num === page
      );

      if (currentPageIndex !== -1) {
        if (!updatedPagesResults[file][currentPageIndex].fields) {
          updatedPagesResults[file][currentPageIndex].fields = {};
        }

        // Update the e_signature field with the new data
        updatedPagesResults[file][currentPageIndex].fields.e_signature.push(
          esignatureData
        );
      }
      console.log(taskId);
      setLocalPagesResults(updatedPagesResults);
      // Make API call to update the page data with the entire e-signature object
      const response = await AuthenticatedApi.put(
        `/api/tasks/${taskId}/override-page-classification`,
        {
          file_name: file,
          page_num: (page + 1).toString(),
          esignature:
            updatedPagesResults[file][currentPageIndex].fields.e_signature, // Send the entire e-signature object
        }
      );

      if (response.data.status === "success") {
        console.log("Page data updated successfully");
      } else {
        console.error("Error updating page data:", response.data);
      }
    } catch (error) {
      console.error("Error updating page data:", error);
    }
  };

  // Handle form submission
  const handleOk = () => {
    form.validateFields().then((values) => {
      const newSignature = {
        signature: values?.signature,
        signature_date: values?.signature_date?.format("YYYY-MM-DD") || "",
        service_date: values?.service_date?.format("YYYY-MM-DD") || "",
        concurrence_signature: values?.concurrence_signature || "",
        concurrence_signature_date:
          values?.concurrence_signature_date?.format("YYYY-MM-DD") || "",
      };

      handleUpdatePageData(values, localPagesResults, setLocalPagesResults);
      // Append the new signature
      appendESignature(newSignature);
      form.resetFields(); // Reset the form
      onOk(); // Close the modal
    });
  };

  return (
    <Modal
      title="Add E-Signature"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Add"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Signature"
          name="signature"
          rules={[{ required: true, message: "Signature is required" }]}
        >
          <TextArea placeholder="Enter signature" />
        </Form.Item>

        <Form.Item
          label="Signature Date"
          name="signature_date"
          rules={[{ required: false, message: "Signature date is required" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Service Date"
          name="service_date"
          rules={[{ required: false, message: "Service date is required" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Concurrence Signature"
          name="concurrence_signature"
          rules={[
            { required: false, message: "Concurrence signature is required" },
          ]}
        >
          <TextArea placeholder="Enter concurrence signature" />
        </Form.Item>

        <Form.Item
          label="Concurrence Signature Date"
          name="concurrence_signature_date"
          rules={[
            {
              required: false,
              message: "Concurrence signature date is required",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        {/* File Select */}
        <Form.Item
          label="File"
          name="file"
          rules={[{ required: false, message: "Please select a file" }]}
        >
          <Select
            placeholder="Select a file"
            allowClear
            style={{ width: "100%" }}
            onChange={handleFileChange} // Added onChange handler
          >
            {fileData.map((file, index) => (
              <Option key={index} value={file.name}>
                {file.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Page Select */}
        <Form.Item
          label="Page"
          name="page"
          rules={[{ required: false, message: "Please select a page" }]}
        >
          <Select
            placeholder="Select a page"
            allowClear
            style={{ width: "100%" }}
          >
            {pageOptions.map((pageNum) => (
              <Option key={pageNum} value={pageNum}>
                Page {pageNum + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddESignatureModal;
