import { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  notification,
  Drawer,
  Tag,
  Dropdown,
  Avatar,
} from "antd";
import {
  SyncOutlined,
  ExclamationOutlined,
  LoadingOutlined,
  MenuOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  SunOutlined,
} from "@ant-design/icons";
import "./NavigationBar.css";
import "../style.css";
import logo from "../assets/logo.png";
import logolight from "../assets/logolight.png";
import { useAuthRole } from "../context/AuthRoleContext";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { useTheme } from "./../context/ThemeContext";
import ConfigContext from "../context/ConfigContext";
import { formatMessage } from "../utils/utilities";
const { Header } = Layout;

function NavigationBar() {
  const { isDarkMode, toggleTheme } = useTheme();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const {
    isAdmin,
    notFound,
    loaded,
    isSuperAdmin,
    isGroupAdmin,
    lastUpdatedAt,
    switchedOrg,
    orgName,
    name,
    setTriggerRefresh: triggerAuthRefresh,
    handleLogout,
  } = useAuthRole();
  const [showExclamation, setShowExclamation] = useState(false);
  const [userInitial, setUserInitial] = useState(null);
  const config = useContext(ConfigContext);

  const getUserInitials = useCallback(async (fullName) => {
    if (!fullName) return "";

    const nameArray = fullName.trim().split(" ");
    if (nameArray.length === 1) {
      return nameArray[0].charAt(0).toUpperCase();
    }

    const initials = nameArray
      .map((name) => name.charAt(0).toUpperCase())
      .join("");
    setUserInitial(initials);
  }, []);

  const fetchData = useCallback(async () => {
    if (showExclamation) return;
    if (!lastUpdatedAt) {
      setShowExclamation(true);
      return;
    }
    try {
      const response = await AuthenticatedApi.get("/api/user");
      const fetchedLastUpdatedAt = new Date(response.data.last_updated_at);
      const storedLastUpdatedAt = new Date(lastUpdatedAt);

      if (fetchedLastUpdatedAt > storedLastUpdatedAt) {
        setShowExclamation(true);
        notification.error({
          message: formatMessage(config.messages.user.account_update),
        });
      } else {
        setShowExclamation(false);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Optionally handle the error
    }
  }, [lastUpdatedAt, showExclamation]);

  useEffect(() => {
    // Fetch data when the component mounts
    if (loaded) {
      if (name) {
        getUserInitials(name);
      }
      fetchData();

      // Set up polling every 60 seconds
      const interval = setInterval(() => {
        fetchData();
      }, 60000);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }
  }, [fetchData, getUserInitials, lastUpdatedAt, loaded, name]);

  const selectedKey = () => {
    const path = location.pathname; // Get the current path
    switch (path) {
      case "/tasks-list":
        return "0";
      case "/usage":
        return "5";
      case "/organization":
        return "6";
      case "/account":
        return "7";
      case "/admin":
        return "9";
      default:
        return "";
    }
  };

  const handleRefreshClick = () => {
    setSyncLoading(true);
    triggerAuthRefresh(true); // Trigger the authentication check in AuthRoleCheck
    setTimeout(() => {
      setShowExclamation(false); // Hide the exclamation mark
      setSyncLoading(false); // Stop the loading animation
      window.location.reload(); // Reload the page
    }, 3000);
  };

  const revertOrg = async () => {
    try {
      const response = await AuthenticatedApi.post("/api/switch_org", {});
      console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const revertBackToOriginalOrg = async () => {
    const isSuccess = await revertOrg();
    if (isSuccess) {
      notification.success({
        message: formatMessage(config.messages.user.revert_back_org),
      });
      triggerAuthRefresh(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const getEnvironmentTag = () => {
    const origin = window.location.origin;
    if (origin.includes("localhost")) return "local";
    if (origin.includes("synthpop-dev")) return "dev";
    if (origin.includes("stage")) return "stage";
    if (origin.includes("app")) return null; // No tag for production
    return null; // Default case if none of the above
  };

  const buttonStyle = showExclamation
    ? {
        position: "absolute",
        right: "56px",
        top: "17px",
        borderColor: "red", // Red border when showExclamation is true
        color: "red", // Red text color
      }
    : {
        position: "absolute",
        right: "22px",
        top: "17px",
      };

  const revertButtonStyle = {
    position: "absolute",
    right: "120px",
    top: "17px",
    borderColor: "red", // Red border when showExclamation is true
    color: "white", // Red text color
    backgroundColor: "red",
  };

  const logoutButtonStyle = {
    position: "absolute",
    right: "0px",
    top: "17px",
  };

  const orgStyleOrg = {
    position: "absolute",
    top: "17px",
    padding: "0px",
    lineHeight: "30px",
  };

  const dropDownMenuItemStyle = {
    marginLeft: "10px",
  };

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const menuMainItems = [
    {
      key: "0",
      label: (
        <Link className="tab-row-item" to="/tasks-list">
          Tasks
        </Link>
      ),
    },
    isAdmin && {
      key: "5",
      label: (
        <Link className="tab-row-item" to="/usage">
          Usage
        </Link>
      ),
    },
    isAdmin && {
      key: "6",
      label: (
        <Link className="tab-row-item" to="/organization">
          Organization
        </Link>
      ),
    },
    (isSuperAdmin || isGroupAdmin) && {
      key: "9",
      label: (
        <Link className="tab-row-item" to="/admin">
          Admin
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link className="tab-row-item" to="/account">
          Account
        </Link>
      ),
    },
  ].filter(Boolean);

  const dropDownMenuItems = [
    {
      key: "settings",
      label: (
        <Link to="/account" style={dropDownMenuItemStyle}>
          <SettingOutlined /> Settings
        </Link>
      ),
    },
    (isSuperAdmin || isGroupAdmin) && {
      key: "admin",
      label: (
        <Link to="/admin" style={dropDownMenuItemStyle}>
          <UserOutlined /> Admin
        </Link>
      ),
    },
    {
      key: "refresh",
      label: (
        <span onClick={handleRefreshClick} style={dropDownMenuItemStyle}>
          <SyncOutlined /> Refresh
        </span>
      ),
    },
    {
      key: "dark-mode",
      label: (
        <span onClick={toggleTheme} style={dropDownMenuItemStyle}>
          <SunOutlined /> Theme: {isDarkMode ? "Dark" : "Light"}
        </span>
      ),
    },
    {
      key: "logout",
      label: (
        <span onClick={handleLogout} style={dropDownMenuItemStyle}>
          <LogoutOutlined /> Logout
        </span>
      ),
    },
  ].filter(Boolean);

  const menuItems = [
    {
      key: "-1",
      label: (
        <div className="logo-container">
          <img
            src={isDarkMode ? logo : logolight}
            alt="Company Logo"
            className="navbar-logo"
          />
        </div>
      ),
    },
    ...menuMainItems,
  ];

  const menu = (
    <Menu
      className="tab-row"
      mode="horizontal"
      selectedKeys={[selectedKey()]}
      items={menuItems}
    />
  );

  return (
    <div>
      {loaded && !notFound && (
        <Header
          className="header"
          style={isDarkMode ? {} : { backgroundColor: "white" }}
        >
          <Button
            className="mobile-menu-trigger"
            style={{ position: "absolute", left: "10px", top: "17px" }}
            onClick={toggleDrawer}
            icon={<MenuOutlined />}
          />
          <div
            className="mobile-menu-trigger logo-container"
            style={{ position: "absolute", left: "100px", top: "0px" }}
          >
            <img
              src={isDarkMode ? logo : logolight}
              alt="Company Logo"
              className="navbar-logo"
            />
          </div>
          <Drawer
            title="Menu"
            placement="right"
            onClose={toggleDrawer}
            open={visible}
          >
            <Menu
              mode="vertical"
              selectedKeys={[selectedKey()]}
              items={menuMainItems}
            />
          </Drawer>
          {menu}
          <span
            style={{
              ...orgStyleOrg,
              color: isDarkMode ? "white" : "black",
              right:
                switchedOrg && showExclamation
                  ? "275px"
                  : switchedOrg
                    ? "211px"
                    : showExclamation
                      ? "120px"
                      : "56px",
            }}
          >
            {getEnvironmentTag() && (
              <Tag color="#2db7f5" style={{ marginLeft: "8px" }}>
                {getEnvironmentTag()}
              </Tag>
            )}
            {orgName}
          </span>
          {switchedOrg && (
            <Button
              className="tab-row-button"
              onClick={revertBackToOriginalOrg}
              style={{
                ...revertButtonStyle,
                right: showExclamation ? "120px" : "56px",
              }}
            >
              Back To Home Org
            </Button>
          )}
          <Dropdown
            menu={{ items: dropDownMenuItems }}
            trigger={["click"]}
            style={logoutButtonStyle}
          >
            <Avatar
              style={{
                backgroundColor: "#87d068",
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "12px",
              }}
              size="large"
            >
              {userInitial}
            </Avatar>
          </Dropdown>
          {showExclamation && (
            <Button
              className="tab-row-button"
              onClick={handleRefreshClick}
              style={buttonStyle}
            >
              {syncLoading ? (
                <LoadingOutlined style={{ color: "inherit" }} />
              ) : (
                <SyncOutlined
                  style={{ color: showExclamation ? "red" : "inherit" }}
                />
              )}
              {showExclamation && !syncLoading && (
                <ExclamationOutlined
                  style={{
                    color: "red",
                    marginRight: "0px",
                    marginLeft: "0px",
                  }}
                />
              )}
            </Button>
          )}
        </Header>
      )}
    </div>
  );
}

export default NavigationBar;
