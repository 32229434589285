import { useState, useContext } from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./MdeStyling.css";
import * as Showdown from "showdown";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";
import { Button, notification, Typography, Modal } from "antd";
import { CopyOutlined, SaveOutlined } from "@ant-design/icons";
import "./CopyIconToClipboard.css"; // Import the CSS file with styling
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import { useTheme } from "../context/ThemeContext";
import ConfigContext from "../context/ConfigContext";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReactDOMServer from "react-dom/server";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: false,
  strikethrough: true,
  tasklists: false,
});

function CopyIconToClipboard(props) {
  const { isDarkMode } = useTheme();
  const { Title } = Typography;
  // eslint-disable-next-line react/prop-types
  const [editableContent, setEditableContent] = useState(props.details);
  const [details, setDetails] = useState(props.details);
  const taskId = props.taskId;
  const fieldType = props.fieldType;
  const isEditable = props.isEditable;
  const setHasUnsavedChanges = props.setHasUnsavedChanges;
  const removeUnsavedChange = props.removeUnsavedChange;
  const dataId = props.data_id;
  const [selectedTab, setSelectedTab] = useState("preview");
  const tableElement = props.tableElement;
  const config = useContext(ConfigContext);

  const mdeToolbar = [
    ["header", "bold", "italic", "strikethrough"],
    ["quote", "code"],
    ["unordered-list", "ordered-list"],
  ];

  const downloadPdf = async () => {
    // Convert markdown to HTML
    const htmlContent = converter.makeHtml(editableContent);

    let tableHtml = "";
    if (props.tableElement) {
      tableHtml = ReactDOMServer.renderToStaticMarkup(props.tableElement);
    }

    const combinedHtmlContent = htmlContent + tableHtml;

    const pdfMakeContent = htmlToPdfmake(combinedHtmlContent);

    // Define document structure
    const docDefinition = {
      content: pdfMakeContent,
    };
    let file_name = "";
    if (props?.tableElement?.props?.selectedData?.selectedRow?.name) {
      file_name =
        props.tableElement.props.selectedData.selectedRow.name
          .split(":")[0]
          .replaceAll("(", "")
          .replaceAll("/", "-")
          .replaceAll(" ", "_")
          .replaceAll(")", "") + "_";
    }
    file_name = file_name + "Order_Analysis.pdf";

    // Generate PDF and trigger download
    pdfMake.createPdf(docDefinition).download(file_name);
  };

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = details;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notification.success({ message: formatMessage(config.messages.copy.copy) });
  };

  const handleEditableInput = (content) => {
    setEditableContent(content);
  };

  const handleEditableBlur = () => {
    //console.log(editableContent);
    setDetails(editableContent);
    if (editableContent !== props.details) {
      setHasUnsavedChanges(dataId);
    }
  };

  const handleSave = () => {
    Modal.confirm({
      title: `Are you sure you want to save the ${props.title} edits?`,
      content: "This will overwrite the existing content.",
      okText: "Save Edits",
      okType: "danger",
      cancelText: "Discard Edits",
      onOk() {
        updateDetails(editableContent);
        removeUnsavedChange(dataId);
      },
      onCancel() {
        setDetails(props.details);
        setEditableContent(props.details);
        removeUnsavedChange(dataId);
      },
    });
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const updateDetails = (edited_details) => {
    if (!taskId || !fieldType) return;

    let data_id = props.data_id || null;
    if (fieldType === "json" && !isValidJSON(edited_details)) {
      notification.error({
        message: formatMessage(config.messages.copy.invalid_json),
      });
      return;
    }

    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        items: [
          {
            output: edited_details,
            data_id: data_id,
          },
        ],
      })
    );

    AuthenticatedApi.post(`/api/v2/tasks/${taskId}/push_info`, formData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        } else {
          console.log(response.data.message);
        }
        notification.success({
          message: formatMessage(config.messages.tasks.output_updated, {
            title: props.title,
          }),
        });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
  };

  return (
    <div className="generated-letter" style={generatedLetterBackgroundStyle}>
      <Title level={3}>{props.title}</Title>
      {tableElement}
      <div className="button-container">
        <Button
          icon={<CopyOutlined />}
          onClick={copyToClipboard}
          title="Copy to clipbord"
        ></Button>
        {isEditable && (
          <>
            <Button
              id={`save-updated-${dataId}`}
              className="save-updated"
              icon={<SaveOutlined />}
              onClick={handleSave}
              title="Save updated changes."
            ></Button>
          </>
        )}
      </div>
      {fieldType === "text" ? (
        <div style={{ marginTop: "10px" }}>
          <ReactMde
            value={(editableContent || "").replace(/_/g, "\\_")}
            onChange={(e) => {
              handleEditableInput(e);
              handleEditableBlur();
            }}
            readOnly={!isEditable}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(converter.makeHtml(markdown))
            }
            toolbarCommands={!isEditable ? [] : mdeToolbar} // No toolbar when read-only.
          />
          <br />
          <button onClick={downloadPdf}>Download as PDF</button>
        </div>
      ) : (
        <pre
          contentEditable={isEditable}
          onBlur={handleEditableBlur}
          onInput={(e) => handleEditableInput(e.target.textContent)}
          suppressContentEditableWarning={true}
        >
          {details}
        </pre>
      )}
    </div>
  );
}

export default CopyIconToClipboard;
