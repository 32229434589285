import { useState, useEffect, useRef, useContext } from "react";
import { Modal, Form, Input, Button, notification } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
import { BugOutlined } from "@ant-design/icons";

function BugReportModal(props) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const lastErrorRef = useRef(null); // Using useRef to hold the value across renders without causing re-renders
  const task = props.selectedRow;
  const config = useContext(ConfigContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isNarrowScreen = screenWidth < 1500; // Adjust the threshold as needed

  useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      console.log("ddddd: ", error);
      lastErrorRef.current = {
        message,
        source,
        lineno,
        colno,
        stack: error ? error.stack : null,
      };
    };

    const handleUnhandledRejection = (event) => {
      console.log("ddddd: ", event.reason.message);
      lastErrorRef.current = {
        message: event.reason.message,
        stack: event.reason.stack,
      };
    };

    const handleAxiosError = (event) => {
      const axiosError = event.detail;

      // Extracting basic message and stack
      const basicMessage =
        axiosError && axiosError.message
          ? axiosError.message
          : "Axios encountered an error";
      const stack = axiosError && axiosError.stack ? axiosError.stack : null;

      // Extracting request details
      const requestURL = axiosError.config
        ? axiosError.config.url
        : "URL not available";
      const requestMethod = axiosError.config
        ? axiosError.config.method
        : "Method not available";
      const requestData =
        axiosError.config && axiosError.config.data
          ? axiosError.config.data
          : "Data not available";

      // Extracting response details if available
      const responseStatus = axiosError.response
        ? axiosError.response.status
        : "Status not available";
      const responseStatusText = axiosError.response
        ? axiosError.response.statusText
        : "Status text not available";

      const message = `
          Basic Error: ${basicMessage}
          Request URL: ${requestURL}
          Request Method: ${requestMethod}
          Request Data: ${requestData}
          Response Status: ${responseStatus}
          Response Status Text: ${responseStatusText}
      `;

      lastErrorRef.current = {
        message,
        stack,
      };
    };

    window.addEventListener("axiosError", handleAxiosError);
    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection
      );
      window.removeEventListener("axiosError", handleAxiosError);
    };
  }, []);

  const onSubmit = async (values) => {
    try {
      const systemInfo = {
        os: window.navigator.platform,
        browser: window.navigator.userAgent,
        task_uuid: task ? task.uuid : null,
      };

      if (values.description.length <= 0) {
        notification.error({
          message: "Error",
          description: "Please describe the bug!",
        });
        return;
      }

      const error = lastErrorRef.current ? lastErrorRef.current : {};
      const reportData = {
        ...systemInfo,
        ...values,
        ...error,
      };
      setVisible(false);
      notification.success({
        message: formatMessage(config.messages.bug_reports.sending),
      });
      // Post this data to your backend
      AuthenticatedApi.post("/api/bug-report", reportData)
        .then(() => {
          notification.success({
            message: formatMessage(config.messages.bug_reports.sent),
          });
          form.resetFields();
          setVisible(false);
        })
        .catch((error) => {
          const errorMessage = handleError(error);
          notification.error({ message: errorMessage });
        });
    } catch (error) {
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  return (
    <>
      <Button
        type="default"
        danger={true}
        icon={<BugOutlined />}
        onClick={() => setVisible(true)}
        title="Report an Issue."
      >
        {isNarrowScreen ? "" : "Report an Issue."}
      </Button>
      <Modal
        title="Bug Report"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label="Please tell us what happened"
            name="description"
            rules={[{ required: false, message: "Please describe the bug!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <p style={{ color: "red" }}>
              {formatMessage(config.messages.bug_reports.disclaimer)}
            </p>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default BugReportModal;
