import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { Form, Input, Button, notification, Upload, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { UnAuthenticatedApi } from "../utils/MyApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
import "./TaskSteps.css";
import TaskIssues from "./TaskIssues";
import PatientInfoForm from "./PatientInfoForm";
import DocumentTable from "./DocumentTable";

const TaskResubmitForm = (props) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [issues, setIssues] = useState(props.issues);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isTempAccess, setIsTempAccess] = useState(false);
  const [tempToken, setTempToken] = useState(null);
  const [submitted, setSubmitted] = useState(false); // TODO: Use this to show a success message
  const [enabledRequestLink, setEnabledRequestLink] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null); // TODO: Use this to show a success message
  const [updatedValidatedForm, setUpdatedValidatedForm] = useState(false);
  const [overrideIssues, setOverrideIssues] = useState([]);
  const [relevantDetails, setRelevantDetails] = useState("");
  const refreshResubmittedTask = props.refreshResubmittedTask;
  const config = useContext(ConfigContext);
  const patientInfoDetails = props.patientInfoDetails;
  const patientFormRef = useRef(null);
  const verdicts = props.verdicts;

  const { taskId: routeTaskId } = useParams();
  const taskId = props.taskId || routeTaskId;
  console.log("Task ID: ", taskId);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEmailSend = () => {
    if (!emailToSend) {
      notification.error({
        message: formatMessage(config.messages.user.valid_email),
      });
      return;
    }

    setIsModalVisible(false);
    setIsSendingEmail(true);
    notification.info({
      message: formatMessage(config.messages.user.sending_email),
    });
    const formData = new FormData();
    formData.append("email", emailToSend);

    AuthenticatedApi.post(`/api/tasks/${taskId}/send_link`, formData)
      .then((response) => {
        console.log(response.data);
        console.log("Sending email to:", emailToSend);
        notification.success({
          message: formatMessage(config.messages.user.email_sent),
        });
        setEmailSent(true);
        setIsSendingEmail(false);
      })
      .catch((error) => {
        console.error("Error generating letter: ", error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setIsSubmitDisabled(fileList.length <= 0);
  };

  const requestNewLink = () => {
    console.log("Requesting new link");
    setEnabledRequestLink(false);
    notification.info({
      message: formatMessage(config.messages.user.new_link_request),
    });

    UnAuthenticatedApi.get(`/api/tasks/${taskId}/request_link`, {
      headers: {
        Authorization: `Bearer ${tempToken}`,
        UID: "temp",
      },
    })
      .then((response) => {
        console.log(response.data);
        notification.success({
          message: formatMessage(config.messages.user.success_new_link_request),
        });
      })
      .catch((error) => {
        console.error("Error generating letter: ", error);
        const errorMessage = handleError(error);
        setEnabledRequestLink(true);
        notification.error({ message: errorMessage });
      });
  };

  const clear = () => {
    form.resetFields();
    setFileList([]);
    setIsSubmitDisabled(true);
    setOverrideIssues([]);
  };

  const onFinish = () => {
    setIsSubmitDisabled(true);
    let data_items = [];

    if (relevantDetails.length > 0) {
      data_items.push({
        name: "Relevant Details",
        tag: "relevant-details",
        data_type: "text",
        data: relevantDetails,
      });
    }

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let pushData = {
      new_task_data: data_items,
      items: [],
      override_issues: overrideIssues,
    };

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }

    if (updatedValidatedForm) {
      const patientData = patientFormRef.current.handleReSubmit(
        form.getFieldsValue()
      );
      console.log(patientData); // Log or handle the combined data
      pushData = { ...pushData, ...patientData };
    }

    formData.append("data", JSON.stringify(pushData));

    console.log(formData);
    try {
      if (!isTempAccess) {
        console.log("Submitting data to the server");
        if (refreshResubmittedTask) {
          refreshResubmittedTask(taskId);
        }
        AuthenticatedApi.post(`/api/v2/tasks/${taskId}/push_info`, formData)
          .then((response) => {
            console.log(response.data);
            if (refreshResubmittedTask) {
              refreshResubmittedTask(taskId);
            }
            notification.success({
              message: formatMessage(config.messages.user.resubmit),
            });
            clear();
            // TODO: Complete this
          })
          .catch((error) => {
            clear();
            console.error("Error: ", error);
            const errorMessage = handleError(error);
            notification.error({ message: errorMessage });
          });
      } else {
        UnAuthenticatedApi.post(`/api/v2/tasks/${taskId}/push_info`, formData, {
          headers: { Authorization: `Bearer ${tempToken}`, UID: "temp" },
        })
          .then((response) => {
            console.log(response.data);
            notification.success({
              message: formatMessage(config.messages.user.resubmit),
            });
            clear();
            setSubmitted(true);
          })
          .catch((error) => {
            clear();
            console.error("Error: ", error);
            const errorMessage = handleError(error);
            notification.error({ message: errorMessage });
          });
      }
    } catch (error) {
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  const fetchDataAndUpdate = useCallback(
    async (temp_access) => {
      try {
        let headers = {};
        if (temp_access) {
          const params = new URLSearchParams(location.search);
          const token = params.get("token");
          if (token) {
            localStorage.setItem("tempToken", token);
            setTempToken(token);
          }
          headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              UID: "temp",
            },
          };
        }

        if (taskId === "-1") {
          setIssues([]);
          return;
        }

        let response;
        if (temp_access) {
          response = await UnAuthenticatedApi.get(
            `/api/tasks/${taskId}`,
            headers
          );
        } else {
          response = await AuthenticatedApi.get(`/api/tasks/${taskId}`);
        }

        console.log(response.data);
        if (response.data.status === "Blocked") {
          setIssues(response.data.metadata.data_incomplete_reasons);
          const extractedFields = response.data.metadata_items.find(
            (item) => item.tag === "extracted-fields"
          );
          if (extractedFields) {
            setPatientDetails(extractedFields.data.demographics);
          }
          if (response.data.uploaded_at && temp_access) {
            setSubmitted(true);
            setEnabledRequestLink(true);
          }
        }
      } catch (error) {
        console.error("Error fetching additional data:", error);
        if (error.response && error.response.status === 401) {
          setEnabledRequestLink(true);
          notification.error({
            message: formatMessage(config.messages.user.unauthorized),
          });
          return;
        } else {
          const errorMessage = handleError(error);
          notification.error({ message: errorMessage });
        }
        setIssues([]);
      }
    },
    [location.search, taskId]
  ); // Added dependencies to the dependency array

  const handleIssueOverride = (overrideData) => {
    setOverrideIssues((prev) => [...prev, overrideData]);
  };

  useEffect(() => {
    const fetchAndUpdate = async () => {
      if (!issues && taskId) {
        const params = new URLSearchParams(location.search);
        const token = params.get("token");
        console.log(token);
        if (token) {
          setIsSendingEmail(true);
          setIsTempAccess(true);
        }
        await fetchDataAndUpdate(token !== null);
      }
    };
    fetchAndUpdate();
    if (props.issues) {
      setIssues(props.issues);
    }
  }, [fetchDataAndUpdate, issues, location.search, taskId, props.issues]);

  return (
    <>
      {!issues && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>No Access.</div>
      )}
      {issues && !submitted && (
        <>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{ padding: "1%" }}
          >
            <div>
              {taskId !== "-1" ? (
                <>
                  {taskId !== "-1" && (
                    <>
                      {/* Display all the issues */}
                      <div style={{ marginBottom: "20px" }}>
                        <TaskIssues
                          taskId={taskId}
                          issues={issues}
                          verdicts={verdicts}
                          onIssueOverride={handleIssueOverride}
                          setIsSubmitDisabled={setIsSubmitDisabled}
                          relevanDetails={relevantDetails}
                          setRelevantDetails={setRelevantDetails}
                        />
                      </div>
                    </>
                  )}
                  <h3 style={{ marginTop: "0px" }}>
                    Upload additional records and details
                    {!emailSent && taskId !== "-1" && !isTempAccess && (
                      <>
                        {" "}
                        or
                        <Button
                          type="link"
                          disabled={isSendingEmail}
                          onClick={showModal}
                        >
                          Request files by email
                        </Button>
                      </>
                    )}
                  </h3>
                  <Form.Item name="file_upload">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Upload
                        beforeUpload={(file) => {
                          const validTypes = [
                            "application/pdf",
                            "image/jpeg",
                            "image/png",
                          ];
                          const isFileValid = validTypes.includes(file.type);
                          if (!isFileValid) {
                            notification.error({
                              message: formatMessage(
                                config.messages.tasks.invalid_file,
                                {
                                  file_list: "PDF, JPG/JPEG, PNG, and TIFF",
                                }
                              ),
                            });
                          }
                          return isFileValid;
                        }}
                        multiple={true}
                        onChange={(info) => {
                          handleUpload(info);
                        }}
                        fileList={fileList}
                        itemRender={(originNode) => {
                          // Remove any tooltips
                          return React.cloneElement(originNode, { title: "" });
                        }}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          title="Upload PDF, JPEG or PNG files."
                        >
                          Select Files
                        </Button>
                        <span style={{ marginLeft: "10px" }}>
                          Upload PDF, JPEG or PNG files.
                        </span>
                      </Upload>
                    </div>
                  </Form.Item>
                  {patientDetails && (
                    <div style={{ marginBottom: "20px" }}>
                      <h4
                        style={{
                          marginTop: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        Patient Information
                      </h4>
                      <div>
                        <label htmlFor="first_name">First Name: </label>
                        <span id="first_name">{patientDetails.first_name}</span>
                      </div>
                      <div>
                        <label htmlFor="last_name">Last Name: </label>
                        <span id="last_name">{patientDetails.last_name}</span>
                      </div>
                      <div>
                        <label htmlFor="DOB">Date of Birth: </label>
                        <span id="DOB">{patientDetails.DOB}</span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <h3 style={{ marginTop: "0px" }}>
                  Upload patient record files for initial evaluation:
                </h3>
              )}
              {taskId !== "-1" && (
                <>
                  {patientInfoDetails && (
                    <>
                      {patientInfoDetails.metadata.identified_documents &&
                        patientInfoDetails.metadata.identified_documents
                          .length > 0 && (
                          <div>
                            <h4 style={{ marginTop: "20px" }}>Documents</h4>
                            <DocumentTable
                              dataSource={
                                patientInfoDetails.metadata.identified_documents
                              }
                              fileData={patientInfoDetails.fileData}
                            />
                          </div>
                        )}
                      <>
                        <h4 style={{ marginTop: "0px" }}>
                          Fill in or validate information in the fields below
                        </h4>

                        <PatientInfoForm
                          ref={patientFormRef}
                          patientInfoDetail={{
                            ...patientInfoDetails,
                            setIsSubmitDisabled: setIsSubmitDisabled,
                            setUpdatedValidatedForm: setUpdatedValidatedForm,
                          }}
                        />
                      </>
                    </>
                  )}
                </>
              )}
              <div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitDisabled}
                    title="Upload and resubmit the task."
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
          <Modal
            title="Send Email"
            open={isModalVisible}
            onOk={handleEmailSend}
            onCancel={handleCancel}
          >
            <Form>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input onChange={(e) => setEmailToSend(e.target.value)} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
      {submitted && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          You have already submitted the required information. To resubmit,
          please request a new link.
        </div>
      )}
      {enabledRequestLink && (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <Button
            type="link"
            onClick={requestNewLink}
            style={{ marginLeft: "10px" }}
          >
            Request Link
          </Button>
        </div>
      )}
    </>
  );
};

export default TaskResubmitForm;
